import { colors } from "../foundations/colors";

export const codeStyles = {
  baseStyle: {
    fontFamily: `IBM Plex Mono,"courier new"`
  },

  variants: {
    "mandala-code": {
      color: colors.mandala.black[900],
      bgColor: colors.mandala.black[300]
    }
  }
};
