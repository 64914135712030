import { colors } from "../foundations/colors";

export const buttonStyles = {
  baseStyle: props => {
    const { isDisabled } = props;

    return {
      color: "#FFF",
      fontSize: "0.875em",
      border: "none",
      borderRadius: "3px",
      cursor: "pointer",
      ...(isDisabled && {
        _disabled: {
          bgColor: colors.mandala.black[300]
        }
      })
    };
  },
  variants: {
    "ddl-solid": ({ colorScheme, isDisabled }) => ({
      borderRadius: "20px",
      bgColor: colors.mandala[colorScheme][700],
      _hover: {
        textDecoration: "none",
        bgColor: colors.mandala[colorScheme][500],
        ...(isDisabled && { _disabled: { bgColor: colors.mandala.black[300] } })
      },
      _focus: {
        boxShadow: "none"
      },
      _loading: {
        bgColor: colors.mandala[colorScheme][500]
      }
    }),
    solid: ({ colorScheme, isDisabled }) => ({
      bgColor: colors.mandala[colorScheme][700],
      _hover: {
        bgColor: colors.mandala[colorScheme][500],
        textDecoration: "none",
        ...(isDisabled && { _disabled: { bgColor: colors.mandala.black[300] } })
      }
    }),
    outline: ({ colorScheme, isDisabled }) => ({
      bgColor: colors.mandala[colorScheme][50],
      border: "solid 1px",
      color: colors.mandala[colorScheme][700],
      borderColor: colors.mandala[colorScheme][200],
      _hover: {
        bgColor: colors.mandala[colorScheme][100],
        color: colors.mandala[colorScheme][500],
        ...(isDisabled && { _disabled: { bgColor: colors.mandala.black[300] } })
      }
    }),
    transparent: ({ colorScheme, isDisabled }) => ({
      bgColor: "transparent",
      color: colors.mandala[colorScheme][700],
      _disabled: { bgColor: "transparent" },
      _hover: {
        bgColor: colors.mandala[colorScheme][100],
        color: colors.mandala[colorScheme][500],
        ...(isDisabled && { _disabled: { bgColor: colors.mandala.black[300] } })
      }
    }),
    link: ({ isDisabled }) => ({
      bgColor: "transparent",
      color: colors.mandala.blue[700],
      _hover: {
        color: colors.mandala.blue[500],
        ...(isDisabled && { _disabled: { bgColor: colors.mandala.black[300] } })
      }
    }),
    navbar: {
      width: "100%",
      bg: "transparent",
      color: colors.mandala.black[700],
      border: "none",
      borderLeft: "4px solid white",
      borderRadius: "0",
      fontWeight: "500",
      cursor: "pointer",
      justifyContent: "left",
      _hover: {
        bg: colors.mandala.black[200],
        borderLeft: `4px solid ${colors.mandala.blue[700]}`,
        color: colors.mandala.blue[700],
        fontWeight: "700"
      }
    },
    "navbar-active": {
      width: "100%",
      bg: colors.mandala.black[200],
      border: "none",
      borderLeft: colors.mandala.blue[700],
      color: colors.mandala.blue[700],
      borderRadius: "0",
      fontWeight: "700",
      cursor: "pointer",
      justifyContent: "left"
    },
    "navbar-header-active": {
      width: "100%",
      bg: colors.mandala.black[100],
      border: "none",
      borderLeft: `4px solid ${colors.mandala.black[100]}`,
      color: colors.mandala.blue[700],
      borderRadius: "0",
      fontWeight: "700",
      cursor: "pointer",
      justifyContent: "left"
    },
    "expandable-cell": {
      bg: "transparent",
      position: "absolute",
      right: 2,
      size: "xs",
      rounded: "full",
      margin: 0,
      padding: 0,
      minWidth: "fit-content",
      height: "fit-content",
      outline: "none",
      _hover: {
        opacity: 0.5
      }
    },
    "sidebar-item": ({ isActivePage = false, isOnParentPage = false }) => ({
      display: "inline-flex",
      borderLeft: "4px solid transparent",
      borderRadius: 0,
      width: "full",
      pb: 1,
      pl: 2,
      pt: 1,
      whiteSpace: "nowrap",
      justifyContent: "flex-start",
      fontSize: "md",
      fontWeight: isActivePage ? 700 : 400,
      color: isActivePage
        ? colors.mandala.blue[600]
        : colors.mandala.black[800],
      borderLeftColor: isActivePage
        ? colors.mandala.blue[700]
        : isOnParentPage
        ? colors.mandala.blue[700]
        : "transparent",
      backgroundColor: isActivePage
        ? colors.mandala.black[200]
        : isOnParentPage
        ? colors.mandala.black[200]
        : "transparent",
      _hover: {
        backgroundColor: colors.mandala.black[200],
        borderLeftColor: colors.mandala.blue[700],
        color: colors.mandala.blue[600]
      }
    }),
    "sidebar-child-item": ({ isActivePage = false }) => ({
      display: "inline-flex",
      borderLeft: "4px solid transparent",
      borderRadius: 0,
      width: "full",
      pb: 1,
      pl: 2,
      pt: 1,
      whiteSpace: "nowrap",
      justifyContent: "flex-start",
      fontSize: "md",
      fontWeight: isActivePage ? 700 : 400,
      borderLeftColor: isActivePage ? colors.mandala.blue[700] : "transparent",
      color: isActivePage
        ? colors.mandala.blue[600]
        : colors.mandala.black[800],
      backgroundColor: isActivePage
        ? colors.mandala.blue[200]
        : colors.mandala.black[50],
      _hover: {
        backgroundColor: colors.mandala.black[200],
        borderLeftColor: colors.mandala.blue[700],
        color: colors.mandala.blue[600]
      }
    })
  },
  defaultProps: {
    colorScheme: "blue",
    variant: "solid"
  }
};
